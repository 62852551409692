import GraphqlTable from '@/components/graphqlTable';
import { getRowProps } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import PageSection from '@/components/page/section';
import { InvoicesRead } from '@/data/commerce/invoice.graphql';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import safeDateFns from '@/helpers/safeFormat';
import useUserInfo from '@/providers/auth/useUserInfo';
import { HouseAccount, Order, QueryInvoicesReadArgs } from '@/types/schema';
import { useTheme } from '@mui/material';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import MobileRowPanel from '../../../commerce/components/mobileRow';
import InvoicesMultiActions from '../../../commerce/invoices/actions/multiActions';
import useInvoiceActions from '../../../commerce/invoices/actions/tableActions';
import RowPanel from '../../../commerce/invoices/rowPanel';
import { columns } from '../../../commerce/invoices/table';

export const getFilteredDates = ( month, startOfWeek, endOfWeek, timezone ) => {
	const startDate = startOfWeek
		? safeDateFns.utcToZonedTime( startOfWeek, timezone )
		: startOfMonth( utcToZonedTime( new Date( month ), timezone ) );
	const endDate = endOfWeek
		? safeDateFns.utcToZonedTime( endOfWeek, timezone )
		: endOfMonth( utcToZonedTime( new Date( month ), timezone ) );
	return { startDate, endDate };
};
export default function AccountInvoices( {
	month = '',
	allMonths,
	startOfWeek = null,
	endOfWeek = null,
	allWeeks,
	timezone,
}: {
	month?: string,
	allMonths?: boolean,
	startOfWeek?: Date,
	endOfWeek?: Date,
	allWeeks?: boolean,
	timezone: string
} ) {
	const houseAccount = useGraphqlResult<HouseAccount>();
	const theme = useTheme();
	const { staff } = useUserInfo();
	const dark = theme.palette.mode === 'dark';
	
	const { startDate, endDate } = getFilteredDates( month, startOfWeek, endOfWeek, timezone );
	
	const generateText = ( month, allMonths, startOfWeek, endOfWeek ) => {
		if ( month || allMonths ) {
			return allMonths ? 'all months' : format( new Date( month ), 'MMMM' );
		} else {
			return allWeeks
				? 'all weeks'
				: `${format( startOfWeek, 'EEE, MMM d' )} - ${format( endOfWeek, 'EEE, MMM d' )}`;
		}
		
	};
	
	return (
		<PageSection primary={`Generated Invoices for ${generateText( month, allMonths, startDate, endDate )}`}>
			<GraphqlTable<Order, QueryInvoicesReadArgs>
				returnNullOnEmpty
				showFooter
				searchable
				disableUrlSync
				queryKey='invoice'
				query={InvoicesRead}
				pageSizeOptions={[ 5, 10, 25, 50 ]}
				subscription={{ INVOICE: staff.company.id }}
				columns={columns}
				rowProps={( row ) => getRowProps( row, dark )}
				variables={{
					options: {
						filter: {
							houseAccount: houseAccount.id,
							type        : 'ACCOUNT',
							metadata    : { templateName: null },
							$and        : [ {
								$or: allMonths || allWeeks ? undefined : [
									{ serviceDate: { $gte: startDate, $lte: endDate } },
									{
										$and: [
											{ serviceDate: { $eq: null } },
											{ createdAt: { $gte: startDate, $lte: endDate } },
										],
									},
								],
							},
							],
						},
					},
				}}
				hiddenTableColumns='generatedAccountInvoices'
				initialState={{
					hiddenColumns: staff.metadata?.generatedAccountInvoices ? staff.metadata?.generatedAccountInvoices : [
						'id',
						'taxTotal',
						'updatedAt',
						'client.id',
						'metadata.signature',
						'metadata.sentDates',
						'houseAccount.id',
						'management:location',
						'houseAccount',
						'serviceDate',
						'po',
						'type',
						'dueDate',
					],
				}}
				expandedComponent={( row ) => <RowPanel row={row}/>}
				useActions={useInvoiceActions}
				renderMultiActions={( invoices, clearRows, selectedRows ) => (
					<InvoicesMultiActions
						invoices={invoices}
						clearRows={clearRows}
						selectedInvoices={selectedRows}
					/>
				)}
				mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
			/>
		</PageSection>
	);
}
