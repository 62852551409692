import Loading from '@/components/loading';
import PageSection from '@/components/page/section';
import VerticalSwipeableTabViews from '@/components/verticalSwipeableTabViews';
import { mutateGraphQL } from '@/data/apollo';
import { useMenu } from '@/providers/menu';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Box, Button, ListItemText, MenuItem, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
	addDays,
	addMonths,
	differenceInMonths,
	endOfWeek,
	format,
	lastDayOfMonth,
	startOfMonth,
	startOfWeek,
} from 'date-fns';
import { toUpper } from 'lodash-es';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HouseAccountWrite } from '../../../../../data/management/houseAccount.graphql';
import { useGraphqlResult } from '../../../../../data/query/graphqlProvider';
import { safeConvertToZonedTime } from '../../../../../helpers/safeFormat';
import { HouseAccount, HouseAccountStatementTypes, MutationHouseAccountWriteArgs } from '../../../../../types/schema';
import InvoicesPerMonth from './invoicesPerMonth';
import InvoicesPerWeek from './invoicesPerWeek';

export const getWeeks = () => {
	const currentDate = safeConvertToZonedTime( new Date() );
	
	const lastDayOfAugust = lastDayOfMonth( safeConvertToZonedTime( new Date( '2023-08-02' ) ) );
	
	let startDate = startOfWeek( lastDayOfAugust, { weekStartsOn: 1 } );
	
	const weeklyDates = [];
	
	while ( startDate <= currentDate ) {
		
		const endDate = endOfWeek( startDate, { weekStartsOn: 1 } );
		
		const name = `${format( startDate, 'MMM d' )} - ${format( endDate, 'MMM d' )}`;
		
		weeklyDates.push( {
			value: [ startDate, endDate ],
			name,
			count: null,
		} );
		
		startDate = addDays( startDate, 7 );
	}
	return [ ...weeklyDates, { value: [ new Date(), new Date() ], name: 'All Weeks', count: null } ];
};

const september = new Date( '09/2/2023' );

export const numberOfMonths = differenceInMonths( addMonths( startOfMonth( new Date() ), 2 ), september );

const getMonths = () => [ ...new Array( numberOfMonths ), new Date() ].map( ( date, i ) => {
	const month = i < numberOfMonths ? addMonths( september, i ) : date;
	return {
		value: format( month, 'MM/dd/yyyy' ),
		count: null,
		name : i < numberOfMonths ? format( month, 'MMM yyyy' ) : 'All Months',
	};
} );

export default function Invoices() {
	const houseAccount = useGraphqlResult<HouseAccount>();
	const [ viewType, setViewType ] = useState<HouseAccountStatementTypes>( houseAccount.statementType );
	const [ isLoading, setIsLoading ] = useState( false );
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const { showMenu } = useMenu();
	const monthTabs: { value: string, name: string, count: number }[] = useMemo( () => getMonths(), [ viewType ] );
	
	const weekTabs: { value: Date[], name: string, count: number }[] = useMemo( () => getWeeks(), [ viewType ] );
	
	useEffect( () => {
		setIsLoading( false );
	}, [ houseAccount.statementType ] );
	
	if ( isMobile ) return null;
	
	return (
		<PageSection primary={t( 'common:invoices' )}>
			<Stack direction='row' alignItems='center' spacing={1} mb={1}>
				<Typography variant='h5'>
					Orders View Mode:
				</Typography>
				<Box sx={{ textAlign: 'left' }}>
					<Button
						variant='text'
						color='primary'
						endIcon={<ExpandMoreIcon/>}
						onClick={( e ) => showMenu( ( { closeMenu } ) => (
							<Fragment>
								{[ 'Monthly', 'Weekly' ].map( ( view, index ) => (
									<MenuItem
										key={index}
										selected={viewType === toUpper( view )}
										onClick={async () => {
											const type = toUpper( view ) as HouseAccountStatementTypes;
											setViewType( type );
											closeMenu();
											setIsLoading( true );
											await mutateGraphQL<MutationHouseAccountWriteArgs>( {
												mutation : HouseAccountWrite,
												variables: { id: houseAccount.id, input: { statementType: type } },
											} );
											await queryClient.invalidateQueries( [ 'houseAccount' ] );
										}}>
										<ListItemText primary={view === 'Monthly' ? view : 'Weekly (Mon - Sun)'}/>
									</MenuItem>
								) )}
							</Fragment>
						), e.currentTarget )}>
						{viewType}
					</Button>
				</Box>
			</Stack>
			{isLoading ? <Loading/> : houseAccount.statementType === 'MONTHLY' ? (
				<VerticalSwipeableTabViews
					disableScrollOnTabs
					renderTabs={monthTabs}
					defaultTab={monthTabs.length - 1}
					renderContent={( index ) => {
						const tab = monthTabs[ index ];
						return <InvoicesPerMonth name={tab.name} month={tab.value}/>;
					}}
				/>
			) : (
				<VerticalSwipeableTabViews
					disableScrollOnTabs
					renderTabs={weekTabs}
					defaultTab={weekTabs.length - 1}
					renderContent={( index ) => {
						const tab = weekTabs[ index ];
						return <InvoicesPerWeek dates={tab.value} name={tab.name}/>;
					}}
				/>
			)}
		</PageSection>
	);
}
