import { useMenu } from '@/providers/menu';
import { ListRounded as ListRoundedIcon } from '@mui/icons-material';
import {
	Box,
	IconButton,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Stack,
	StackProps,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@mui/material';
import { DebouncedFunc, isEmpty, isNumber, throttle } from 'lodash-es';
import { nanoid } from 'nanoid';
import type { ReactNode } from 'react';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import useEventEffect from '../hooks/useEventEffect';

const tabHeight = 30;

function useThrottledOnScroll( callback, delay ) {
	const throttledCallback = useMemo<DebouncedFunc<any>>( () => throttle( callback, delay ), [ callback, delay ] );
	useEventEffect( window, 'scroll', throttledCallback, [ throttledCallback ] );
}

export default function VerticalSwipeableTabViews( {
	renderTabs,
	renderContent,
	trigger,
	appBarHeight = 0,
	defaultTab = 0,
	disableScrollOnTabs,
	containerStackProps,
}: {
	renderTabs: Array<{ name: string, count: number, value?: string | Date[] }>,
	renderContent: ( index: number ) => ReactNode,
	trigger?: boolean,
	appBarHeight?: number,
	defaultTab?: number,
	disableScrollOnTabs?: boolean,
	containerStackProps?: StackProps
} ) {
	const { showMenu } = useMenu();
	const theme = useTheme();
	const isDark = theme.palette.mode === 'dark';
	const [ activeTab, setActiveTab ] = useState( defaultTab || 0 );
	const stackRef = useRef<HTMLDivElement>( null );
	const itemsClientRef = useRef<( HTMLElement | null )[]>( [] );
	const clickedRef = useRef( false );
	const unsetClickedRef = useRef<ReturnType<typeof setTimeout> | null>( null );
	
	const uniqueID = useMemo( nanoid, [] );
	
	itemsClientRef.current = renderTabs.map( ( tab,
		index ) => document.getElementById( `${uniqueID}_${index}` ) as HTMLElement | null );
	const tabsWrapperHeight = stackRef.current?.offsetHeight || 0;
	
	const findActiveIndex = useCallback( () => {
		if ( disableScrollOnTabs || !itemsClientRef.current.length || clickedRef.current ) return;
		
		let active;
		if ( document.documentElement.scrollTop < 0 ) {
			active = 0;
		} else {
			for ( let i = itemsClientRef.current.length - 1; i >= 0; i -= 1 ) {
				const item = itemsClientRef.current[ i ];
				
				if ( item && item.offsetTop < document.documentElement.scrollTop + tabsWrapperHeight + appBarHeight ) {
					active = i;
					break;
				}
			}
		}
		if ( active !== undefined ) setActiveTab( active );
	}, [ activeTab, tabsWrapperHeight, appBarHeight, disableScrollOnTabs ] );
	
	// Corresponds to 10 frames at 60 Hz
	useThrottledOnScroll( findActiveIndex, 166 );
	
	const handleClick = ( index, closeMenu? ) => () => {
		clearTimeout( unsetClickedRef.current as ReturnType<typeof setTimeout> );
		clickedRef.current = true;
		unsetClickedRef.current = setTimeout( () => {
			clickedRef.current = false;
		}, 1000 );
		
		setActiveTab( index );
		if ( !disableScrollOnTabs ) {
			const targetElement = document.getElementById( `${uniqueID}_${index}` );
			if ( targetElement ) {
				window?.scrollTo( {
					top     : targetElement.getBoundingClientRect().top + window.scrollY - tabsWrapperHeight - appBarHeight,
					behavior: 'smooth',
				} );
			}
		}
		closeMenu?.();
	};
	
	return (
		<Fragment>
			<Stack
				ref={stackRef}
				component={Paper}
				direction='row'
				spacing={1}
				alignItems='center'
				{...containerStackProps}
				sx={{
					'bgcolor'       : 'background.alpha',
					'backdropFilter': 'blur(9px)',
					'position'      : 'sticky',
					'top'           : trigger ? appBarHeight : 0,
					'boxShadow'     : 0,
					'pb'            : .5,
					'px'            : 1,
					'pt'            : 1,
					'borderRadius'  : 0,
					'border'        : 0,
					'borderColor'   : 'divider',
					'width'         : '100%',
					'zIndex'        : 'drawer',
					':after'        : {
						content   : '""',
						position  : 'absolute',
						left      : 0,
						width     : '100%',
						transition: 'all 0.3s ease-in-out',
						height    : '1px',
						bgcolor   : 'divider',
						top       : 43,
						opacity   : trigger ? 1 : 0,
					},
					'.MuiTabs-root' : {
						'.MuiTabs-scrollButtons.Mui-disabled': {
							opacity: 0.3,
						},
						'.MuiTabs-indicator'                 : {
							height        : tabHeight,
							display       : 'flex',
							justifyContent: 'center',
							bgcolor       : 'text.primary',
							borderRadius  : 5,
						},
						'minHeight'                          : tabHeight,
						'.MuiTab-root'                       : {
							'color'         : 'text.secondary',
							'mr'            : .5,
							'p'             : 1,
							'pb'            : '6px !important',
							'minHeight'     : tabHeight,
							'borderRadius'  : 5,
							'textTransform' : 'capitalize',
							'&.Mui-selected': { color: isDark ? '#000000' : '#ffffff', zIndex: 2 },
							'&:hover'       : { bgcolor: 'divider', borderRadius: 5 },
							'bgcolor'       : 'divider',
						},
					},
					...containerStackProps?.sx,
				}}>
				{!isEmpty( renderTabs ) && (
					<IconButton
						onClick={( e ) => showMenu( ( { closeMenu } ) => (
							<MenuList sx={{ p: 0, width: 200 }}>
								{renderTabs.map( ( tab, index ) => (
									<MenuItem
											key={index}
											sx={{ my: 0 }}
											onClick={handleClick( index, closeMenu )}>
										<ListItemText primary={tab.name}/>
										<Typography>{isNumber( tab.count ) ? tab.count : null}</Typography>
									</MenuItem>
									) )}
							</MenuList>
							), e.currentTarget, {
								anchorOrigin   : { vertical: 'bottom', horizontal: 'left' },
								transformOrigin: { vertical: 'top', horizontal: 'left' },
							},
						)}>
						<ListRoundedIcon/>
					</IconButton>
				)}
				<Tabs
					allowScrollButtonsMobile
					scrollButtons='auto'
					value={activeTab}
					variant='scrollable'
					TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan'/> }}>
					{renderTabs.map( ( tab, index ) => (
						<Tab
							key={index}
							label={tab.name}
							onClick={handleClick( index )}
						/>
					) )}
				</Tabs>
			</Stack>
			<Box p={2}>
				{disableScrollOnTabs ? renderContent( activeTab ) : renderTabs.map( ( section, index ) => (
					<Box key={index} id={`${uniqueID}_${index}`}>
						{renderContent( index )}
					</Box>
				) )}
			</Box>
		</Fragment>
	);
}
