import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { useGraphQL } from '@/data';
import { queryGraphQL } from '@/data/apollo';
import { MonthlyHouseAccountInvoicesRead } from '@/data/commerce/invoice.graphql';
import { HouseAccountInvoicesTotalsByMonth } from '@/data/management/houseAccount.graphql';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import {
	VerifyHouseAccountInvoicesModal,
} from '@/pages/dashboard/management/houseAccounts/form/modals/verifyHouseAccountInvoices';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import type {
	Client,
	HouseAccount,
	Order,
	QueryHouseAccountInvoicesTotalsByMonthArgs,
	QueryMonthlyHouseAccountInvoicesReadArgs,
} from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { generateHAInvoice } from '../utils';
import AccountInvoices from './accountInvoices';
import HouseAccountInvoices from './houseAccountInvoices';
import Header from './invoicesHeader';

export default function InvoicesPerMonth( { month, name }: { month: string, name: string } ) {
	const houseAccount = useGraphqlResult<HouseAccount>();
	const { staff } = useUserInfo();
	const timeZone = getBrowserTimezone();
	const confirmDialog = useConfirmDialog();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const event = useEvents();
	const { t } = useTranslation();
	const allMonths = name === 'All Months';
	
	const { data, isFetching } = useGraphQL<QueryHouseAccountInvoicesTotalsByMonthArgs>( {
		queryKey : [ 'houseAccountInvoicesTotalsByMonthArgs' ],
		query    : HouseAccountInvoicesTotalsByMonth,
		variables: { houseAccountId: houseAccount.id, month: allMonths ? null : month, timeZone },
	}, { enabled: Boolean( houseAccount.id ) } );
	
	const formattedMonth = format( new Date( month ), 'MMM yyyy' );
	const balance = data?.houseAccountInvoicesTotalsByMonth?.balance;
	const spentToDate = data?.houseAccountInvoicesTotalsByMonth?.spentToDate;
	
	const generateInvoiceForText = () => t( 'management:generate-invoice-for', {
		month: allMonths
			? t( 'management:all-months' )
			: format( new Date( month ), 'MMMM' ),
	} );
	
	const spentOnText = t( 'management:spent-on', { month: allMonths ? t( 'management:all-months' ) : formattedMonth } );
	const dueForText = t( 'management:balance-due-for', {
		month: allMonths
			? t( 'management:all-months' )
			: formattedMonth,
	} );
	
	const generateInvoice = async ( monthlyInvoices: Order[] ) => {
		const location = monthlyInvoices.find( ( invoice ) => invoice.companyLocation )?.companyLocation;
		await generateHAInvoice( monthlyInvoices, staff, houseAccount, month, houseAccount?.client as Client || monthlyInvoices?.[ 0 ]?.client as Client, location );
		event.emit( 'reload.graphqlQuery', true );
	};
	
	return (
		<Stack spacing={1} justifyContent='space-between'>
			<Stack direction='row' sx={{ justifyContent: 'space-between', mt: 1 }} spacing={1}>
				<Header
					balance={balance}
					spentToDate={spentToDate}
					dueForText={dueForText}
					spentOnText={spentOnText}
					isFetching={isFetching}
				/>
				<AsyncLoadingButton
					startIcon={<AddCircleIcon/>}
					variant='contained'
					sx={{ width: 200 }}
					onClick={async () => {
						const value = await confirmDialog( {
								title  : generateInvoiceForText(),
								message: t( 'management:invoice-will-be-gen-for-month', {
									month: allMonths
										? t( 'management:all-months' )
										: t( 'management:the-month-of', { month: format( new Date( month ), 'MMMM' ) } ),
								} ),
							},
						);
						if ( !value ) return;
						enqueueSnackbar( t( 'management:invoice-generating-snackbar' ), { variant: 'info' } );
						
						const { monthlyHouseAccountInvoicesRead } = await queryGraphQL<QueryMonthlyHouseAccountInvoicesReadArgs>( {
							query    : MonthlyHouseAccountInvoicesRead,
							variables: {
								month,
								HAId   : houseAccount.id,
								HAEmail: houseAccount?.email || null,
								allMonths,
								timeZone,
							},
							
						} );
						
						const monthlyInvoices = monthlyHouseAccountInvoicesRead?.items;
						if ( isEmpty( monthlyInvoices ) ) {
							throw 'No invoices available.';
						}
						const invoicesWithMergedTo = monthlyInvoices.filter( ( invoice ) => invoice.metadata?.mergedTo );
						if ( !isEmpty( invoicesWithMergedTo ) ) {
							showModal( VerifyHouseAccountInvoicesModal, { maxWidth: 'sm' }, {
								invoicesWithMergedTo,
								onSubmit: async () => await generateInvoice( monthlyInvoices ),
							} );
						} else {
							await generateInvoice( monthlyInvoices );
						}
						
					}}>
					{generateInvoiceForText()}
				</AsyncLoadingButton>
			</Stack>
			<AccountInvoices allMonths={allMonths} month={month} timezone={timeZone}/>
			<HouseAccountInvoices allMonths={allMonths} month={month} timezone={timeZone}/>
		</Stack>
	);
}
