import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { useGraphQL } from '@/data';
import { queryGraphQL } from '@/data/apollo';
import { MonthlyHouseAccountInvoicesRead } from '@/data/commerce/invoice.graphql';
import { HouseAccountInvoicesTotalsByMonth } from '@/data/management/houseAccount.graphql';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import {
	VerifyHouseAccountInvoicesModal,
} from '@/pages/dashboard/management/houseAccounts/form/modals/verifyHouseAccountInvoices';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import type {
	Client,
	HouseAccount,
	Order,
	QueryHouseAccountInvoicesTotalsByMonthArgs,
	QueryMonthlyHouseAccountInvoicesReadArgs,
} from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { generateHAInvoice } from '../utils';
import AccountInvoices from './accountInvoices';
import HouseAccountInvoices from './houseAccountInvoices';
import Header from './invoicesHeader';

export default function InvoicesPerWeek( { dates, name }: { dates: Date[], name: string } ) {
	
	const timeZone = getBrowserTimezone();
	const houseAccount = useGraphqlResult<HouseAccount>();
	const { staff } = useUserInfo();
	const confirmDialog = useConfirmDialog();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const event = useEvents();
	const { t } = useTranslation();
	const allWeeks = name === 'All Weeks';
	
	const { data, isFetching } = useGraphQL<QueryHouseAccountInvoicesTotalsByMonthArgs>( {
		queryKey : [ 'houseAccountInvoicesTotalsByMonthArgs' ],
		query    : HouseAccountInvoicesTotalsByMonth,
		variables: {
			houseAccountId: houseAccount.id,
			startOfWeek   : allWeeks ? null : dates[ 0 ],
			endOfWeek     : allWeeks ? null : dates[ 1 ],
			timeZone,
		},
	}, { enabled: Boolean( houseAccount.id ) } );
	
	const balance = data?.houseAccountInvoicesTotalsByMonth?.balance;
	const spentToDate = data?.houseAccountInvoicesTotalsByMonth?.spentToDate;
	
	const generateInvoiceForText = () => t( 'management:generate-invoice-for', {
		month: name,
	} );
	
	const spentOnText = t( 'management:spent-on', { month: name } );
	const dueForText = t( 'management:balance-due-for', {
		month: name,
	} );
	
	const generateInvoice = async ( monthlyInvoices: Order[] ) => {
		const location = monthlyInvoices.find( ( invoice ) => invoice.companyLocation )?.companyLocation;
		const date = safeFormatInTimeZone( dates[ 1 ], 'Pp', timeZone );
		await generateHAInvoice( monthlyInvoices, staff, houseAccount, date, houseAccount?.client as Client || monthlyInvoices?.[ 0 ]?.client as Client, location );
		event.emit( 'reload.graphqlQuery', true );
	};
	
	return (
		<Stack spacing={1} justifyContent='space-between'>
			<Stack direction='row' sx={{ justifyContent: 'space-between', mt: 1 }} spacing={1}>
				<Header
					balance={balance}
					spentToDate={spentToDate}
					dueForText={dueForText}
					spentOnText={spentOnText}
					isFetching={isFetching}
				/>
				<AsyncLoadingButton
					startIcon={<AddCircleIcon/>}
					variant='contained'
					sx={{ width: 200 }}
					onClick={async () => {
						const value = await confirmDialog( {
								title  : generateInvoiceForText(),
								message: `An invoice will be generate for ${allWeeks ? 'all weeks' : `the week ${name}`}`,
							},
						);
						if ( !value ) return;
						enqueueSnackbar( t( 'management:invoice-generating-snackbar' ), { variant: 'info' } );
						
						const { monthlyHouseAccountInvoicesRead } = await queryGraphQL<QueryMonthlyHouseAccountInvoicesReadArgs>( {
							query    : MonthlyHouseAccountInvoicesRead,
							variables: {
								startOfWeek: dates[ 0 ],
								endOfWeek  : dates[ 1 ],
								timeZone,
								HAId       : houseAccount.id,
								HAEmail    : houseAccount?.email || null,
								allMonths  : allWeeks,
							},
							
						} );
						
						const monthlyInvoices = monthlyHouseAccountInvoicesRead?.items;
						if ( isEmpty( monthlyInvoices ) ) {
							throw 'No invoices available.';
						}
						const invoicesWithMergedTo = monthlyInvoices.filter( ( invoice ) => invoice.metadata?.mergedTo );
						if ( !isEmpty( invoicesWithMergedTo ) ) {
							showModal( VerifyHouseAccountInvoicesModal, { maxWidth: 'sm' }, {
								invoicesWithMergedTo,
								onSubmit: async () => await generateInvoice( monthlyInvoices ),
							} );
						} else {
							await generateInvoice( monthlyInvoices );
						}
					}}>
					{generateInvoiceForText()}
				</AsyncLoadingButton>
			</Stack>
			<AccountInvoices allWeeks={allWeeks} startOfWeek={dates[ 0 ]} endOfWeek={dates[ 1 ]} timezone={timeZone}/>
			<HouseAccountInvoices allWeeks={allWeeks} startOfWeek={dates[ 0 ]} endOfWeek={dates[ 1 ]} timezone={timeZone}/>
		</Stack>
	);
}
