import { Box, BoxProps, Fade, FadeProps, Skeleton, SkeletonProps } from '@mui/material';
import { Fragment, ReactNode } from 'react';

export default function ConditionalSkeleton( {
	isLoading,
	fadeProps,
	children,
	skeleton,
	boxProps,
	skipAnimation,
	...skeletonProps
}: {
	isLoading?: boolean,
	fadeProps?: Omit<FadeProps, 'children'>,
	skeleton?: ReactNode,
	children: JSX.Element | ReactNode,
	boxProps?: BoxProps,
	skipAnimation?: boolean
} & SkeletonProps ): JSX.Element {
	
	if ( isLoading ) {
		// Render the custom skeleton if provided, else default to the standard Skeleton
		return (
			<Fragment>
				{skeleton || (
					<Skeleton
						animation='wave'
						{...skeletonProps}
						sx={{ borderRadius: 1, ...skeletonProps.sx }}
					/>
				)}
			</Fragment>
		);
	}
	
	if ( skipAnimation ) return <Fragment>{children}</Fragment>;
	
	return (
		<Fade in timeout={900} {...fadeProps}>
			<Box {...boxProps}>
				{children}
			</Box>
		</Fade>
	);
}
