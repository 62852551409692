import ConditionalSkeleton from '@/components/animations/conditionalSkeleton';
import { Paper, Stack, Typography } from '@mui/material';
import currencyFormat from '../../../../../helpers/currencyFormat';

export default function Header( { balance, spentToDate, spentOnText, dueForText, isFetching } ) {
	
	return (
		<Stack direction='row' spacing={1}>
			<Stack spacing={1}>
				<Typography>{spentOnText}</Typography>
				<Paper
					className='center'
					sx={{ width: 200, height: 100, p: 1 }}>
					<ConditionalSkeleton
						isLoading={isFetching}
						height={10}
						width={50}
						fadeProps={{ timeout: 500 }}>
						<Typography variant='h3'>
							{currencyFormat( spentToDate )}
						</Typography>
					</ConditionalSkeleton>
				</Paper>
			</Stack>
			<Stack spacing={1}>
				<Typography>{dueForText}</Typography>
				<Paper
					className='center'
					sx={{ width: 200, height: 100, p: 1 }}>
					<ConditionalSkeleton
						isLoading={isFetching}
						height={10}
						width={50}
						fadeProps={{ timeout: 500 }}>
						<Typography variant='h3'>
							{currencyFormat( balance )}
						</Typography>
					</ConditionalSkeleton>
				</Paper>
			</Stack>
		</Stack>
	);
}
